/*
 * theme.scss - Control over the current theme
 */

/*
 * Colors
 */

$color-background: rgb(20, 20, 20) !default;
$color-background2: rgb(30, 30, 30) !default;
$color-background3: rgb(40, 40, 40) !default;

$color-frame: rgb(119, 74, 204) !default;
$color-frame2: rgb(42, 34, 78) !default;

$color-font-default: white !default;


/*
 * Code Type Colors
 */

$color-code-keyword: #569CD6 !default;
$color-code-comment: #57A64A !default;
$color-code-function: #FF8000 !default;
$color-code-string: #D69D85 !default;
$color-code-number: #B5CEA8 !default;
$color-code-member: #BDB76B !default;
$color-code-type: #FFD700 !default;


/*
 * Variables
 */
$border-thickness: 0.15em !default;
$border-radius: 0.45em !default;


/*
 * Fonts
 */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap');

$font-family-normal: 'Nunito', sans-serif !default;
$font-family-code: 'Firacode', monospace !default;