/*
 * classes/flex.css - Various flex divs for positioning elements
 */

div.flex_grid {
    display: flex;
    flex-direction: column;
}

div.flex_grid_center {
    display: flex;
    margin: 0px;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
}

div.flex_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}