/*
 * cards.css - Defines commonly used card-like divs within the page
 * Such as the project cards (and various permutations)
 */

/*
 * MODALS
 */
center_modal {
    width: 50%;
    height: 50%;
    background: rgb(79, 50, 176);

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;

    border-radius: 0.45em;
}


/* Cards */
project_card {
    display: inline-block;
    margin: 1rem;
    padding: 1em;
    width: 20em;

    background: rgb(20, 20, 20);

    border: $border-thickness solid $color-frame;
    border-radius: $border-radius;
}

project_card.auto_width {
    width: auto;
}

project_card.about_me {
    width: 45em;
}

project_card.social {
    width: 12.5em;
}

project_card.minor {
    margin: 0.5rem;
    width: 35em;
}

project_card.video_card {
    width: auto;
    height: auto;
    padding: 0.5em;
}

project_card.fill_page {
    width: 100%;
    height: 100%;
}

project_card.blog_post {
    width: 45em;
}

/* Cards */
section_card {
    display: inline-block;
    margin: 1rem;
    padding: 0.4em;
    width: 20em;

    /* background: rgb(21, 20, 20); */

    /* background: rgb(0, 0, 0, 0.8); */
    /* background: rgb(21, 14, 41); */
    background: rgb(35, 28, 65);

    /* backdrop-filter: blur(5em); */

    border: $border-thickness solid $color-frame2;
    border-radius: $border-radius;
}

section_card.auto_width {
    width: auto;
}

page_card {
    position: absolute;

    width: auto;

    background: rgb(35, 28, 65);

    filter: blur(0em, 0em, 0.2em, rgba(0, 0, 0, 0.35));

    border: $border-thickness solid $color-frame;
    border-radius: $border-radius;
}