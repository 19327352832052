/*                                
 * text.css - CSS styling of text
 */

/*
 * HYPERLINK PLACEHOLDER
 */
a {
    color: #99c6ff;
    transition: all ease 100ms;
}

a:visited {
    color: #68aaff
}

a:visited:hover {
    color: #c4e0ff;
}

a:hover {
    color: #c4e0ff;
}

a:active {
    color: #c4e0ff;
}

/*
 * CLASSES
 */
.centered {
    text-align: center
}

.white,
.white:visited,
.white:hover,
.white:active {
    color: white;
}

.no-top {
    margin-top: 0px;
}

.no-bottom {
    margin-bottom: 0px;
}

.no-filter {
    backdrop-filter: none;
    filter: none;
}