/*                              
 * button.scss - Button styling 
 */                              

button {
    border: $border-thickness solid $color-frame;
    border-radius: $border-radius;
    background-color: $color-background2;
    color: white;
    padding: 1em;
    transition: all ease 100ms;
}

button:hover {
    color: #d6d6d6;
    background-color: $color-background3;
}

button:active {
    color: #707070;
    background-color: $color-background;
    /* padding: 1.25em; */
}