/*
 * Code styling
 */

/* TODO: Organize this better */

pre,
code {
    font-family: $font-family-code;
    font-size: 0.9375em;

    border: $border-thickness solid $color-frame;
    border-radius: $border-radius;
    
    background-color: $color-background;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;

    >code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}

div.highlight {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*
 * Highlighted code styling
 */
.k,
.kt,
.cp {
    color: $color-code-keyword;
}

.nf,
.nd,
.se {
    color: $color-code-function;
}

.mi {
    color: $color-code-number;
}

.s,
.sh,
.cpf {
    color: $color-code-string;
}

.nc {
    color: $color-code-type;
}

.m {
    color: $color-code-member;
}

.c1 {
    color: $color-code-comment;
}